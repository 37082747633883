exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-associates-js": () => import("./../../../src/pages/associates.js" /* webpackChunkName: "component---src-pages-associates-js" */),
  "component---src-pages-bizlab-js": () => import("./../../../src/pages/bizlab.js" /* webpackChunkName: "component---src-pages-bizlab-js" */),
  "component---src-pages-college-js": () => import("./../../../src/pages/college.js" /* webpackChunkName: "component---src-pages-college-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-corporate-js": () => import("./../../../src/pages/corporate.js" /* webpackChunkName: "component---src-pages-corporate-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-josim-js": () => import("./../../../src/pages/josim.js" /* webpackChunkName: "component---src-pages-josim-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mentor-js": () => import("./../../../src/pages/mentor.js" /* webpackChunkName: "component---src-pages-mentor-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our_team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-student-registration-js": () => import("./../../../src/pages/student-registration.js" /* webpackChunkName: "component---src-pages-student-registration-js" */),
  "component---src-pages-subscriber-js": () => import("./../../../src/pages/subscriber.js" /* webpackChunkName: "component---src-pages-subscriber-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-why-biz-lab-js": () => import("./../../../src/pages/why_BizLab.js" /* webpackChunkName: "component---src-pages-why-biz-lab-js" */)
}

